// Position utilities
// Version 1.0.1
//
// Custom styles for position utilities.

// Vertically & Horizontally center. Note: parent must have position: relative
.center-both {
  position: absolute;
  top: 50%;
  left: 50%;
  @include prefix(transform, translate(-50%, -50%), "webkit" "ms");
}
.center-md-both {
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 50%;
    left: 50%;
    @include prefix(transform, translate(-50%, -50%), "webkit" "ms");
  }
}
.center-sm-down-both {
  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 50%;
    left: 50%;
    @include prefix(transform, translate(-50%, -50%), "webkit" "ms");
  }
}

// Flexbox shorthand utilities classes
.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-v-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.content-v-center-left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

// Absolute top
// Similiar to fixed top, but absolute
.absolute-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
