// Convenience function used to set content property
// e.g. content: var($ai-angle-right)
@function var($ai-var) {
  @return unquote('"#{ $ai-var }"');
}

// Main
$ai-address-book: \e900;
$ai-address-card: \e901;
$ai-alarm-clock: \e902;
$ai-album-collection: \e903;
$ai-album: \e904;
$ai-angle-down: \e905;
$ai-angle-left: \e906;
$ai-angle-right: \e907;
$ai-angle-up: \e908;
$ai-arrow-alt-circle-down: \e909;
$ai-arrow-alt-circle-left: \e90a;
$ai-arrow-alt-circle-right: \e90b;
$ai-arrow-alt-circle-up: \e90c;
$ai-arrow-circle-down: \e90d;
$ai-arrow-circle-left: \e90e;
$ai-arrow-circle-right: \e90f;
$ai-arrow-circle-up: \e910;
$ai-arrow-down: \e911;
$ai-arrow-from-bottom: \e912;
$ai-arrow-from-left: \e913;
$ai-arrow-from-right: \e914;
$ai-arrow-from-top: \e915;
$ai-arrow-left: \e916;
$ai-arrow-right: \e917;
$ai-arrow-to-bottom: \e918;
$ai-arrow-to-left: \e919;
$ai-arrow-to-right: \e91a;
$ai-arrow-to-top: \e91b;
$ai-backspace: \e91c;
$ai-ban: \e91d;
$ai-barcode-alt: \e91e;
$ai-barcode-read: \e91f;
$ai-bars: \e920;
$ai-bell: \e921;
$ai-book-alt: \e922;
$ai-book-open: \e923;
$ai-bookmark: \e924;
$ai-box-open: \e925;
$ai-box: \e926;
$ai-briefcase: \e927;
$ai-building: \e928;
$ai-bullhorn: \e929;
$ai-bullseye: \e92a;
$ai-calendar-day: \e92b;
$ai-calendar: \e92c;
$ai-camera: \e92d;
$ai-caret-circle-down: \e92e;
$ai-caret-circle-left: \e92f;
$ai-caret-circle-right: \e930;
$ai-caret-circle-up: \e931;
$ai-cart-plus: \e932;
$ai-chart-bar: \e933;
$ai-chart-pie: \e934;
$ai-check-circle: \e935;
$ai-check: \e936;
$ai-check-square: \e937;
$ai-chevron-circle-down: \e938;
$ai-chevron-circle-left: \e939;
$ai-chevron-circle-right: \e93a;
$ai-chevron-circle-up: \e93b;
$ai-chevron-down: \e93c;
$ai-chevron-left: \e93d;
$ai-chevron-right: \e93e;
$ai-chevron-up: \e93f;
$ai-circle-notch: \e940;
$ai-circle: \e941;
$ai-clipboard: \e942;
$ai-clock: \e943;
$ai-clone: \e944;
$ai-cog: \e945;
$ai-coin: \e946;
$ai-coins: \e947;
$ai-comment-alt-dots: \e948;
$ai-comment-alt-lines: \e949;
$ai-comment-alt: \e94a;
$ai-comment-dots: \e94b;
$ai-comment: \e94c;
$ai-comments-alt: \e94d;
$ai-comments: \e94e;
$ai-compass: \e94f;
$ai-copy: \e950;
$ai-credit-card-blank: \e951;
$ai-cube: \e952;
$ai-database: \e953;
$ai-desktop: \e954;
$ai-dice-d6: \e955;
$ai-dollar-sign: \e956;
$ai-download: \e957;
$ai-ellipsis-h: \e958;
$ai-ellipsis-v: \e959;
$ai-envelope: \e95a;
$ai-exchange-alt: \e95b;
$ai-exchange: \e95c;
$ai-exclamation-circle: \e95d;
$ai-exclamation: \e95e;
$ai-exclamation-square: \e95f;
$ai-exclamation-triangle: \e960;
$ai-expand: \e961;
$ai-external-link-alt: \e962;
$ai-external-link: \e963;
$ai-external-link-square-alt: \e964;
$ai-external-link-square: \e965;
$ai-eye-slash: \e966;
$ai-eye: \e967;
$ai-file-chart-line: \e968;
$ai-file-chart-pie: \e969;
$ai-file-image: \e96a;
$ai-file: \e96b;
$ai-filter: \e96c;
$ai-fingerprint: \e96d;
$ai-flag-alt: \e96e;
$ai-flask: \e96f;
$ai-folder: \e970;
$ai-frown-open: \e971;
$ai-frown: \e972;
$ai-gem: \e973;
$ai-genderless: \e974;
$ai-gift: \e975;
$ai-globe-africa: \e976;
$ai-globe-americas: \e977;
$ai-globe-asia: \e978;
$ai-globe-europe: \e979;
$ai-globe: \e97a;
$ai-grin-alt: \e97b;
$ai-grin: \e97c;
$ai-grip-lines: \e97d;
$ai-grip-lines-vertical: \e97e;
$ai-heart-circle: \e97f;
$ai-heart: \e980;
$ai-history: \e981;
$ai-home-alt: \e982;
$ai-home-lg-alt: \e983;
$ai-id-badge: \e984;
$ai-id-card: \e985;
$ai-image: \e986;
$ai-info-circle: \e987;
$ai-info: \e988;
$ai-info-square: \e989;
$ai-landmark: \e98a;
$ai-laptop: \e98b;
$ai-list: \e98c;
$ai-list-ul: \e98d;
$ai-location-arrow: \e98e;
$ai-lock: \e98f;
$ai-long-arrow-alt-down: \e990;
$ai-long-arrow-alt-left: \e991;
$ai-long-arrow-alt-right: \e992;
$ai-long-arrow-alt-up: \e993;
$ai-long-arrow-down: \e994;
$ai-long-arrow-left: \e995;
$ai-long-arrow-right: \e996;
$ai-long-arrow-up: \e997;
$ai-map-marked-alt: \e998;
$ai-map-marker-alt: \e999;
$ai-map: \e99a;
$ai-medal: \e99b;
$ai-meh: \e99c;
$ai-microphone-slash: \e99d;
$ai-microphone: \e99e;
$ai-minus-circle: \e99f;
$ai-minus: \e9a0;
$ai-minus-square: \e9a1;
$ai-mobile-android-alt: \e9a2;
$ai-mobile-android: \e9a3;
$ai-money-bill: \e9a4;
$ai-money-bill-wave: \e9a5;
$ai-money-check-alt: \e9a6;
$ai-moon: \e9a7;
$ai-mouse-pointer: \e9a8;
$ai-music-slash: \e9a9;
$ai-music: \e9aa;
$ai-newspaper: \e9ab;
$ai-paper-plane: \e9ac;
$ai-pen: \e9ad;
$ai-phone-alt: \e9ae;
$ai-phone-laptop: \e9af;
$ai-phone-office: \e9b0;
$ai-plane: \e9b1;
$ai-play-circle: \e9b2;
$ai-play: \e9b3;
$ai-plus-circle: \e9b4;
$ai-plus: \e9b5;
$ai-plus-square: \e9b6;
$ai-poll: \e9b7;
$ai-portrait: \e9b8;
$ai-power-off: \e9b9;
$ai-qrcode: \e9ba;
$ai-question-circle: \e9bb;
$ai-question: \e9bc;
$ai-question-square: \e9bd;
$ai-receipt: \e9be;
$ai-redo-alt: \e9bf;
$ai-redo: \e9c0;
$ai-repeat-alt: \e9c1;
$ai-search: \e9c2;
$ai-share-alt: \e9c3;
$ai-share: \e9c4;
$ai-shopping-bag: \e9c5;
$ai-shopping-cart: \e9c6;
$ai-sign-in-alt: \e9c7;
$ai-sign-in: \e9c8;
$ai-sign-out-alt: \e9c9;
$ai-sign-out: \e9ca;
$ai-sliders-h: \e9cb;
$ai-sliders-h-square: \e9cc;
$ai-sliders-v: \e9cd;
$ai-sliders-v-square: \e9ce;
$ai-smile: \e9cf;
$ai-sort-alt: \e9d0;
$ai-sort-amount-down: \e9d1;
$ai-sort-amount-up: \e9d2;
$ai-sort-down: \e9d3;
$ai-sort: \e9d4;
$ai-sort-up: \e9d5;
$ai-spinner-third: \e9d6;
$ai-square: \e9d7;
$ai-stop-circle: \e9d8;
$ai-stop: \e9d9;
$ai-store-alt: \e9da;
$ai-sync-alt: \e9db;
$ai-sync: \e9dc;
$ai-tablet-alt: \e9dd;
$ai-tablet-android: \e9de;
$ai-tachometer: \e9df;
$ai-tag: \e9e0;
$ai-tags: \e9e1;
$ai-thumbs-down: \e9e2;
$ai-thumbs-up: \e9e3;
$ai-ticket-alt: \e9e4;
$ai-ticket: \e9e5;
$ai-times-circle: \e9e6;
$ai-times: \e9e7;
$ai-times-square: \e9e8;
$ai-trash-alt: \e9e9;
$ai-trash: \e9ea;
$ai-trophy-alt: \e9eb;
$ai-trophy: \e9ec;
$ai-truck: \e9ed;
$ai-tv-alt: \e9ee;
$ai-unlock: \e9ef;
$ai-upload: \e9f0;
$ai-usd-circle: \e9f1;
$ai-user-circle: \e9f2;
$ai-user: \e9f3;
$ai-video: \e9f4;
$ai-volume-mute: \e9f5;
$ai-volume-off: \e9f6;
$ai-volume: \e9f7;

// Brands

$ai-opensea: \e923;
$ai-medium: \e921;
$ai-medium-m: \e922;
$ai-discord: \e920;
$ai-tiktok: \e91f;
$ai-android: \e900;
$ai-app-store: \e901;
$ai-app-store-ios: \e902;
$ai-apple: \e903;
$ai-apple-pay: \e904;
$ai-btc: \e905;
$ai-cc-mastercard: \e906;
$ai-cc-paypal: \e907;
$ai-cc-visa: \e908;
$ai-facebook: \e909;
$ai-facebook-f: \e90a;
$ai-facebook-messenger: \e90b;
$ai-facebook-square: \e90c;
$ai-google: \e90d;
$ai-google-play: \e90e;
$ai-instagram: \e90f;
$ai-instagram-square: \e910;
$ai-line: \e911;
$ai-linkedin: \e912;
$ai-linkedin-in: \e913;
$ai-paypal: \e914;
$ai-pinterest: \e915;
$ai-pinterest-p: \e916;
$ai-pinterest-square: \e917;
$ai-twitter: \e918;
$ai-twitter-square: \e919;
$ai-unsplash: \e91a;
$ai-whatsapp: \e91b;
$ai-whatsapp-square: \e91c;
$ai-youtube: \e91d;
$ai-youtube-square: \e91e;
