.sc-login-phone {
  .info-wrapper {
    padding: 1rem;

    background: $dwp-gradient;
    color: $white;
    display: flex;
    align-items: center;
    .ai-info-circle {
      &::before {
        font-size: 2rem;
      }
    }
    .info-text {
      font-size: 12px;
      margin-bottom: 0;
    }
  }
  .info-wrapper-2 {
    padding: 1rem;
    background-color: $ismaya-grey;
    color: $white;
  }
  .form-login {
    .login-label {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      color: $gray-400;
    }
    .input-code-wrapper {
      position: relative;
      width: 100%;
      border-radius: $border-radius;
      border: 1px solid $gray-50;
      height: 56px;
      background-color: rgba($dark, 0);
      padding: 0.75rem;
      padding-left: 0.675rem;
      display: flex;
      justify-content: start;
      align-items: center;
      @media screen and (min-width: 375px) {
        padding-left: 0.75rem;
      }
      .input-code {
        display: flex;
        align-items: center;
        .input-code-flag {
          padding-right: 0.125rem;
          @media screen and (min-width: 375px) {
            padding-right: 0.25rem;
          }
        }
      }
      .label {
        font-size: 12px;
        // color: rgba($black, 0.4);
      }
      &::after {
        content: var($ai-angle-down);
        font-family: "Anti Icons Regular";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;
        // font-size: 1rem;
      }
    }
    .form-text {
      margin-top: 0.75rem;
      text-align: center;
      font-size: 0.8rem;
    }
    input {
      // background-color: $gray-50;
      border: 1px solid $gray-50;
    }
    .select-form {
      .form-control__control {
        border: 1px solid $gray-50;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
    .gender-list {
      .gender-item {
        border: 1px solid $gray-50;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.875rem;
        transition: $transition-base;
        &.active {
          border: 1px solid $primary;
        }
        &.disabled {
          border: 1px solid $gray-50;
          background-color: $gray-50;
        }
      }
    }
    .ic-calendar {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .checkbox-input {
      display: flex;
      align-items: flex-start;
      .checkbox {
        cursor: pointer;
      }
    }
    .form-button {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
    }
  }
}

.sc-login-otp {
  .input-hidden {
    position: fixed;
    left: 0;
    top: -10rem;
    opacity: 0;
  }
  .input-otp {
    .number-wrapper {
      border-bottom: 3px solid $gray-200;
      width: 75%;
      display: flex;
      justify-content: center;
      min-height: 91px;
      &.active {
        border-bottom: 3px solid $primary;
      }
      &.error {
        border-bottom: 3px solid $danger;
      }
      .number-input {
        font-size: 3rem;
        font-weight: bold;
      }
    }
  }
}

.sc-choose-method {
  .method-list {
    .method-item {
      position: relative;
      border: 1px solid $gray-200;
      border-radius: 0.25rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 1rem;
      min-height: 80px;
      &::after {
        content: var($ai-angle-right);
        font-family: "Anti Icons Light";
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.5rem;
      }
    }
  }
}
