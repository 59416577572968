/*
 * Antikode main.scss
 * Version - 3.0.1
 * Bootstrap - 4.5.0
 * 2021 Antikode
 */

// ========================================================================================================================================
// Import
// ========================================================================================================================================

// ==========================================================================
// Bootstrap Base & Custom Variables
// ==========================================================================

// Required functions / mixins used in variables config
@import "~bootstrap/scss/functions"; // DO NOT EDIT
@import "functions/strip-sass"; // e.g. strip unit in SASS
@import "mixins/fonts-custom"; // DO NOT EDIT

// Custom variables
// EDIT THESE VARIABLES
@import "variables/color"; // Set base color variables
@import "variables/options"; // Enable base rounded / shadows
@import "variables/spacing-grid";
@import "variables/border-shadow-modal"; // Set base border, box shadow, and modal variables
@import "variables/typography"; // Set custom fonts and base typography variables
@import "variables/buttons-forms"; // Set buttons / forms base padding and other variables
// @import "variables/breadcrumbs";
@import "variables/transitions";
@import "variables/animations"; // Set general animation duration
// @import "variables/navbar"; // Set navbar height, animation duration, and other variables

// Base styles
@import "base/bootstrap.scss"; // Bootstrap 4 base variables, mixins, and styles

// ==========================================================================
// Optional Plugins
// ==========================================================================

// Font Awesome 5.13.0
// @import "../plugins/fontawesome-pro/css/all.min.css";
// @import "../plugins/fontawesome-pro/scss/fontawesome.scss";

// Locomotive Scroll 4.1.0
// @import "../plugins/locomotive-scroll/locomotive-scroll.scss";

// Anti Icons 1.0.0
@import "../plugins/anti-icons/variables";
@import "../plugins/anti-icons/light/style.css";
@import "../plugins/anti-icons/regular/style.css";
@import "../plugins/anti-icons/solid/style.css";
@import "../plugins/anti-icons/brands/style.css";

// // Icomoon
// @import "../plugins/icomoon/style.css";

// Slick Carousel
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// ==========================================================================
// Antikode Custom Mixins, Utilities, Overriders, and Base
// ==========================================================================

// Custom Mixins
@import "mixins/gradients"; // Gradients mixns
@import "mixins/icons"; // Font awesome mixins
@import "mixins/prefix"; // Vendor-prefixed CSS helper
@import "mixins/position"; // Shorthandizes position declarations
@import "mixins/text-truncate"; // Text truncate multiline

// Plugin Style Overrider
// @import "overrider/datatable";
// @import "overrider/select2";

// Utilities
// @import "utilities/background"; // e.g. .bg-overlay
@import "utilities/buttons"; // e.g. .btn-block-mw-100px, .btn-block-mw-200px, etc.
@import "utilities/grid"; // e.g. .row-0, .row-1, .row-10px, .row-20px, etc.
@import "utilities/position"; // e.g. .center-both, etc.
@import "utilities/sizing"; // e.g. .h-50px, .w-md-100-px, etc.
@import "utilities/spacing"; // e.g. .py-main, .mb-sm-down-1, etc.
@import "utilities/text"; // e.g. .text-truncate-twoline, etc.

// SCSS base component
// @import "base/alert";
@import "base/animations";
// @import "base/background";
@import "base/dropdown";
@import "base/forms";
// @import "base/nav";
// @import "base/post";
// @import "base/reboot";
// @import "base/table";

// ==========================================================================
// Anti Components
// ==========================================================================

@import "/components/anti/buttons/buttons";
@import "/components/anti/buttons/buttons-custom";
@import "/components/anti/card/card";
@import "/components/anti/card/card-custom";
@import "/components/anti/forms/forms";
@import "/components/anti/forms/forms-custom";
@import "/components/anti/image/image";
@import "/components/anti/image/image-custom";
@import "/components/anti/link/link";
@import "/components/anti/link/link-custom";
@import "/components/anti/modal/modal";
@import "/components/anti/modal/modal-custom";
@import "/components/anti/utils/utils";

// ==========================================================================
// Pages Styles
// ==========================================================================
@import "./page/section";
@import "./page/pin";
// ========================================================================================================================================
// Custom Styles
// ========================================================================================================================================
@import "variables/navbar"; // Set navbar height, animation duration, and other variables

// ==========================================================================
// General
// ==========================================================================

html,
body {
  overflow-x: hidden;
  background-color: $dark;
  color: $white;
}

// Insert custom style here

.bottom-fixed {
  width: 100%;
  position: fixed;
  bottom: 1rem;
  left: 0;
}

.bottom-absolute {
  width: 100%;
  position: absolute;
  bottom: 1rem;
  left: 0;
}

.body-sheet {
  height: 100vh;
}

.header-section {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .link {
      text-transform: uppercase;
      color: $primary;
      font-weight: bold;
    }
  }
}

.pin-number-text {
  font-size: 2rem;
  font-weight: $font-weight-bold;
}

.pin-wrapper {
  width: 3rem;
  height: 3rem;
}

.error-text {
  font-size: 1rem;
}

.sc-login-pin {
  .input-hidden {
    position: fixed;
    left: 0;
    top: -10rem;
    opacity: 0;
  }
}

.hidden-otp-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -10px;
  left: 0;
}

.loading-screen {
  z-index: 1000000;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden-pin-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .pin-input {
    width: 60px !important;
    background-color: transparent;
    height: 65px !important;
    outline: none;
    border: none;
    font-size: 3rem;
    color: transparent;
    outline-color: transparent;
    outline-style: none;
    &:focus {
      outline-color: transparent;
      outline-style: none;
    }
  }
}

.select-country-sheet {
  position: relative;
  // [data-rsbs-overlay] {
  //   height: 90%;
  // }
  .header-sheet {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $dark;
    color: $white;
    .back-button {
      position: absolute;
      font-size: 1.5rem;
      margin-top: -0.425rem;
    }
    .search-input {
      margin-top: 1rem;
      .form-control-wrapper.ic-left {
        &::before {
          height: 100%;
          content: var($ai-search);
          width: 2.5rem;
        }
        .form-control {
          height: 36px;
          padding: 0.5rem;
          background-color: $gray-50;
          border: 0px solid $gray-50;
          padding-left: calc(1.5em + 1.25rem + 2px) !important;
        }
      }
    }
  }
  .body-sheet {
    margin-top: 6rem;
    overflow: auto;
    background-color: $dark;
    color: $white;
    .list-country {
      list-style: none;
      padding-left: 0;
      height: 450px;
      .country-item {
        padding: 0.25rem 0;
        border-bottom: 1px solid $gray-100;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .flag {
          font-size: 1.875rem;
        }
      }
    }
  }
}

.text-gold {
  color: $primary;
}

.captcha-wrapper {
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @include media-breakpoint-down(sm) {
    display: block;
    transform: scale(1.15, 1);
    transform-origin: 0 0;
  }
}

// ==========================================================================
// NProgress
// ==========================================================================
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $primary;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $primary, 0 0 5px $primary;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

//TOAST
.toast-wrapper {
  z-index: 100000000000;
  position: fixed;
  width: 100%;
  max-width: 465px;
  padding: 0.7rem;
  transition: $transition-opacity;
  color: $white;
  &.show {
    opacity: 1;
  }
  &.hide {
    opacity: 0;
    padding: 0;
    display: none;
    // top: -$navbar-main-height-desktop;
  }
  .toast-state {
    position: relative;
    border-radius: 5px;
    height: 100%;
    padding: 0.8rem 1rem 0.8rem 1rem;
    &.success {
      // background-color: $green;
      background-color: $light-green;
    }
    &.danger {
      // background-color: $red;
      background-color: $red-grad;
    }
    &.error {
      background-color: $red-grad;
    }
    &.warning {
      background-color: $warning;
    }
    &.verify {
      background-color: $primary;
    }
    &.loading {
      background-color: $gray-600;
    }
    &.info {
      background-color: $gray-800;
    }
    &.nav-toast {
      top: $navbar-height-mobile;
      @include media-breakpoint-up($navbar-expand-up) {
        top: $navbar-height-desktop;
      }
    }

    .close-toast {
      i {
        font-size: 1.5rem;
      }
      position: absolute;
      right: 1rem;
      top: 10%;
      display: flex;
      justify-content: center;
    }
  }
  @include media-breakpoint-up($navbar-expand-up) {
    top: $navbar-main-height-desktop;
  }
}
.lds-dual-ring {
  display: inline-block;
  height: 100%;
}
.lds-dual-ring:after {
  margin-right: 3rem;
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.terms-style {
  font-size: 0.9rem;
  span {
    color: rgba(120, 197, 191, 1);
  }
}

//DATE PICKER
.react-datepicker__year-option {
  font-size: 1rem;
}

.react-datepicker__year-dropdown {
  width: 200px;
}

.react-datepicker__year-read-view {
  width: 200px;
  background-color: $gray-200;
  font-size: 1.1rem;
  margin: 0.5rem 0;
}

//OTP
.otp-input-number {
  width: 75px !important;
  background-color: transparent;
  height: 100px !important;
  outline: none;
  border: none;
  font-size: 3rem;
  color: transparent;
  // background-color: red;
  outline-color: transparent;
  outline-style: none;
  &:focus {
    outline-color: transparent;
    outline-style: none;
  }
}

.otp-sepator {
  margin: 0rem 0.7rem !important;
}

.disclaimer {
  color: $orange;
}

input {
  background-color: rgba($dark, 0) !important;
  color: $white !important;
}
